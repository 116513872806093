@use '../../theming/theming';
@use '../../theming/inspection';
@use '../../style/sass-utils';
@use '../../tokens/token-utils';
@use '../../tokens/m2/mat/full-pseudo-checkbox' as tokens-mat-full-pseudo-checkbox;
@use '../../tokens/m2/mat/minimal-pseudo-checkbox' as tokens-mat-minimal-pseudo-checkbox;

@mixin _palette-styles($theme, $palette-name) {
  @include sass-utils.current-selector-or-root() {
    @include token-utils.create-token-values(tokens-mat-full-pseudo-checkbox.$prefix,
      tokens-mat-full-pseudo-checkbox.get-color-tokens($theme, $palette-name));
    @include token-utils.create-token-values(tokens-mat-minimal-pseudo-checkbox.$prefix,
      tokens-mat-minimal-pseudo-checkbox.get-color-tokens($theme, $palette-name));
  }
}

@mixin base($theme) {}

@mixin color($theme) {
  .mat-primary {
    @include _palette-styles($theme, primary);
  }

  // Default to the accent color. Note that the pseudo checkboxes are meant to inherit the
  // theme from their parent, rather than implementing their own theming, which is why we
  // don't attach to the `mat-*` classes. Also note that this needs to be below `.mat-primary`
  // in order to allow for the color to be overwritten if the checkbox is inside a parent that
  // has `mat-accent` and is placed inside another parent that has `mat-primary`.
  @include _palette-styles($theme, accent);
  .mat-accent {
    @include _palette-styles($theme, accent);
  }

  .mat-warn {
    @include _palette-styles($theme, warn);
  }
}

@mixin typography($theme) {}

@mixin density($theme) {}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-pseudo-checkbox') {
    @include base($theme);
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
