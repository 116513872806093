@use 'sass:map';
@use '@material/switch/switch-theme' as mdc-switch-theme;
@use '@material/form-field/form-field-theme' as mdc-form-field-theme;
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/typography/typography';
@use '../core/tokens/m2/mdc/form-field' as tokens-mdc-form-field;
@use '../core/tokens/m2/mdc/switch' as tokens-mdc-switch;

@mixin base($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, base));
  }
  @else {
    .mdc-switch {
      @include mdc-switch-theme.theme(tokens-mdc-switch.get-unthemable-tokens());
    }
  }
}

@mixin color($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, color));
  }
  @else {
    $is-dark: inspection.get-theme-type($theme) == dark;
    $mdc-switch-color-tokens: tokens-mdc-switch.get-color-tokens($theme);

    // Add values for MDC slide toggles tokens
    .mat-mdc-slide-toggle {
      @include mdc-switch-theme.theme($mdc-switch-color-tokens);
      @include mdc-form-field-theme.theme(tokens-mdc-form-field.get-color-tokens($theme));

      // MDC should set the disabled color on the label, but doesn't, so we do it here instead.
      .mdc-switch--disabled + label {
        color: inspection.get-theme-color($theme, foreground, disabled-text);
      }

      // Change the color palette related tokens to accent or warn if applicable
      &.mat-accent {
        @include mdc-switch-theme.theme(
            tokens-mdc-switch.private-get-color-palette-color-tokens($theme, accent));
      }

      &.mat-warn {
        @include mdc-switch-theme.theme(
            tokens-mdc-switch.private-get-color-palette-color-tokens($theme, warn));
      }
    }
  }
}

@mixin typography($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, typography));
  }
  @else {
    $mdc-switch-typography-tokens: tokens-mdc-switch.get-typography-tokens($theme);

    // Add values for MDC slide toggle tokens
    .mat-mdc-slide-toggle {
      @include mdc-switch-theme.theme($mdc-switch-typography-tokens);
      @include mdc-form-field-theme.theme(tokens-mdc-form-field.get-typography-tokens($theme));
    }
  }
}

@mixin density($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, density));
  }
  @else {
    $density-scale: inspection.get-theme-density($theme);

    .mat-mdc-slide-toggle {
      @include mdc-switch-theme.theme(mdc-switch-theme.density($density-scale));
    }
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-slide-toggle') {
    @if inspection.get-theme-version($theme) == 1 {
      @include _theme-from-tokens(inspection.get-theme-tokens($theme));
    }
    @else {
      @include base($theme);
      @if inspection.theme-has($theme, color) {
        @include color($theme);
      }
      @if inspection.theme-has($theme, density) {
        @include density($theme);
      }
      @if inspection.theme-has($theme, typography) {
        @include typography($theme);
      }
    }
  }
}

@mixin _theme-from-tokens($tokens) {
  @if ($tokens != ()) {
    @include mdc-switch-theme.theme(map.get($tokens, tokens-mdc-switch.$prefix));
  }
}
