@use 'sass:map';
@use '../core/style/sass-utils';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/tokens/m2/mdc/linear-progress' as tokens-mdc-linear-progress;
@use '@material/linear-progress/linear-progress-theme' as mdc-linear-progress-theme;

@mixin base($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, base));
  }
  @else {
    // Add default values for tokens not related to color, typography, or density.
    @include sass-utils.current-selector-or-root() {
      @include mdc-linear-progress-theme.theme(tokens-mdc-linear-progress.get-unthemable-tokens());
    }
  }
}

@mixin _palette-styles($theme, $palette-name) {
  @include mdc-linear-progress-theme.theme(
    tokens-mdc-linear-progress.get-color-tokens($theme, $palette-name));
}

@mixin color($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, color));
  }
  @else {
    .mat-mdc-progress-bar {
      @include _palette-styles($theme, primary);

      &.mat-accent {
        @include _palette-styles($theme, accent);
      }

      &.mat-warn {
        @include _palette-styles($theme, warn);
      }
    }
  }
}

@mixin typography($theme) {}

@mixin density($theme) {}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-progress-bar') {
    @if inspection.get-theme-version($theme) == 1 {
      @include _theme-from-tokens(inspection.get-theme-tokens($theme));
    }
    @else {
      @include base($theme);
      @if inspection.theme-has($theme, color) {
        @include color($theme);
      }
      @if inspection.theme-has($theme, density) {
        @include density($theme);
      }
      @if inspection.theme-has($theme, typography) {
        @include typography($theme);
      }
    }
  }
}

@mixin _theme-from-tokens($tokens) {
  @if ($tokens != ()) {
    @include mdc-linear-progress-theme.theme(map.get($tokens, tokens-mdc-linear-progress.$prefix));
  }
}
