@use 'sass:map';
@use '@material/button/button-text-theme' as mdc-button-text-theme;
@use '@material/button/button-filled-theme' as mdc-button-filled-theme;
@use '@material/button/button-protected-theme' as mdc-button-protected-theme;
@use '@material/button/button-outlined-theme' as mdc-button-outlined-theme;

@use './button-base';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/tokens/token-utils';
@use '../core/typography/typography';
@use '../core/tokens/m2/mdc/filled-button' as tokens-mdc-filled-button;
@use '../core/tokens/m2/mat/filled-button' as tokens-mat-filled-button;
@use '../core/tokens/m2/mdc/outlined-button' as tokens-mdc-outlined-button;
@use '../core/tokens/m2/mat/outlined-button' as tokens-mat-outlined-button;
@use '../core/tokens/m2/mdc/protected-button' as tokens-mdc-protected-button;
@use '../core/tokens/m2/mat/protected-button' as tokens-mat-protected-button;
@use '../core/tokens/m2/mdc/text-button' as tokens-mdc-text-button;
@use '../core/tokens/m2/mat/text-button' as tokens-mat-text-button;
@use '../core/style/sass-utils';

@mixin _text-button-variant($theme, $palette) {
  $mdc-tokens: if($palette,
    tokens-mdc-text-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mdc-text-button.get-color-tokens($theme)
  );

  $mat-tokens: if($palette,
    tokens-mat-text-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mat-text-button.get-color-tokens($theme)
  );

  @include mdc-button-text-theme.theme($mdc-tokens);
  @include token-utils.create-token-values(tokens-mat-text-button.$prefix, $mat-tokens);
}

@mixin _filled-button-variant($theme, $palette) {
  $mdc-tokens: if($palette,
    tokens-mdc-filled-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mdc-filled-button.get-color-tokens($theme)
  );

  $mat-tokens: if($palette,
    tokens-mat-filled-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mat-filled-button.get-color-tokens($theme)
  );

  @include mdc-button-filled-theme.theme($mdc-tokens);
  @include token-utils.create-token-values(tokens-mat-filled-button.$prefix, $mat-tokens);
}

@mixin _protected-button-variant($theme, $palette) {
  $mdc-tokens: if($palette,
    tokens-mdc-protected-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mdc-protected-button.get-color-tokens($theme)
  );

  $mat-tokens: if($palette,
    tokens-mat-protected-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mat-protected-button.get-color-tokens($theme)
  );

  @include mdc-button-protected-theme.theme($mdc-tokens);
  @include token-utils.create-token-values(tokens-mat-protected-button.$prefix, $mat-tokens);
}

@mixin _outlined-button-variant($theme, $palette) {
  $mdc-tokens: if($palette,
    tokens-mdc-outlined-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mdc-outlined-button.get-color-tokens($theme)
  );

  $mat-tokens: if($palette,
    tokens-mat-outlined-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mat-outlined-button.get-color-tokens($theme)
  );

  @include mdc-button-outlined-theme.theme($mdc-tokens);
  @include token-utils.create-token-values(tokens-mat-outlined-button.$prefix, $mat-tokens);
}

@mixin _theme-from-tokens($tokens) {
  @if ($tokens != ()) {
    @include mdc-button-text-theme.theme(map.get($tokens, tokens-mdc-text-button.$prefix));
    @include mdc-button-protected-theme.theme(
      map.get($tokens, tokens-mdc-protected-button.$prefix));
    @include mdc-button-filled-theme.theme(map.get($tokens, tokens-mdc-filled-button.$prefix));
    @include mdc-button-outlined-theme.theme(map.get($tokens, tokens-mdc-outlined-button.$prefix));

    @include token-utils.create-token-values(
      tokens-mat-text-button.$prefix, map.get($tokens, tokens-mat-text-button.$prefix));
    @include token-utils.create-token-values(
      tokens-mat-protected-button.$prefix, map.get($tokens, tokens-mat-protected-button.$prefix));
    @include token-utils.create-token-values(
      tokens-mat-filled-button.$prefix, map.get($tokens, tokens-mat-filled-button.$prefix));
    @include token-utils.create-token-values(
      tokens-mat-outlined-button.$prefix, map.get($tokens, tokens-mat-outlined-button.$prefix));
  }
}

@mixin base($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include theme-from-tokens(inspection.get-theme-tokens($theme, base));
  }
  @else {
    @include sass-utils.current-selector-or-root() {
      @include mdc-button-text-theme.theme(
        tokens-mdc-text-button.get-unthemable-tokens());
      @include mdc-button-filled-theme.theme(
        tokens-mdc-filled-button.get-unthemable-tokens());
      @include mdc-button-protected-theme.theme(
        tokens-mdc-protected-button.get-unthemable-tokens());
      @include mdc-button-outlined-theme.theme(
        tokens-mdc-outlined-button.get-unthemable-tokens());
    }
  }
}

@mixin color($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, color));
  }
  @else {
    @include sass-utils.current-selector-or-root() {
      @include _text-button-variant($theme, null);
      @include _filled-button-variant($theme, null);
      @include _protected-button-variant($theme, null);
      @include _outlined-button-variant($theme, null);
    }

    .mat-mdc-button {
      &.mat-primary {
        @include _text-button-variant($theme, primary);
      }

      &.mat-accent {
        @include _text-button-variant($theme, accent);
      }

      &.mat-warn {
        @include _text-button-variant($theme, warn);
      }
    }

    .mat-mdc-unelevated-button {
      &.mat-primary {
        @include _filled-button-variant($theme, primary);
      }

      &.mat-accent {
        @include _filled-button-variant($theme, accent);
      }

      &.mat-warn {
        @include _filled-button-variant($theme, warn);
      }
    }

    .mat-mdc-raised-button {
      &.mat-primary {
        @include _protected-button-variant($theme, primary);
      }

      &.mat-accent {
        @include _protected-button-variant($theme, accent);
      }

      &.mat-warn {
        @include _protected-button-variant($theme, warn);
      }
    }

    .mat-mdc-outlined-button {
      &.mat-primary {
        @include _outlined-button-variant($theme, primary);
      }

      &.mat-accent {
        @include _outlined-button-variant($theme, accent);
      }

      &.mat-warn {
        @include _outlined-button-variant($theme, warn);
      }
    }
  }
}

@mixin typography($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, typography));
  }
  @else {
    @include sass-utils.current-selector-or-root() {
      $text-typography-tokens: tokens-mdc-text-button.get-typography-tokens($theme);
      $filled-typography-tokens: tokens-mdc-filled-button.get-typography-tokens($theme);
      $outlined-typography-tokens: tokens-mdc-outlined-button.get-typography-tokens($theme);
      $protected-typography-tokens: tokens-mdc-protected-button.get-typography-tokens($theme);

      @include mdc-button-text-theme.theme($text-typography-tokens);
      @include mdc-button-filled-theme.theme($filled-typography-tokens);
      @include mdc-button-outlined-theme.theme($outlined-typography-tokens);
      @include mdc-button-protected-theme.theme($protected-typography-tokens);
    }
  }
}

@mixin density($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, density));
  }
  @else {
    $density-scale: theming.clamp-density(inspection.get-theme-density($theme), -3);

    @include sass-utils.current-selector-or-root() {
      $text-density-tokens: tokens-mdc-text-button.get-density-tokens($theme);
      $filled-density-tokens: tokens-mdc-filled-button.get-density-tokens($theme);
      $outlined-density-tokens: tokens-mdc-outlined-button.get-density-tokens($theme);
      $protected-density-tokens: tokens-mdc-protected-button.get-density-tokens($theme);

      @include mdc-button-text-theme.theme($text-density-tokens);
      @include mdc-button-filled-theme.theme($filled-density-tokens);
      @include mdc-button-outlined-theme.theme($outlined-density-tokens);
      @include mdc-button-protected-theme.theme($protected-density-tokens);
    }

    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-unelevated-button,
    .mat-mdc-outlined-button {
      // TODO: tokenize the touch target visibility.
      @include button-base.mat-private-button-touch-target-density($density-scale);
    }
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-button') {
    @if inspection.get-theme-version($theme) == 1 {
      @include _theme-from-tokens(inspection.get-theme-tokens($theme));
    }
    @else {
      @include base($theme);
      @if inspection.theme-has($theme, color) {
        @include color($theme);
      }
      @if inspection.theme-has($theme, density) {
        @include density($theme);
      }
      @if inspection.theme-has($theme, typography) {
        @include typography($theme);
      }
    }
  }
}
